import { useState, useEffect } from "react";
import logo from "../img/logo.png";
import { NavLink } from "react-router-dom";
import "./nav.css";
import ModalBook from "../components/BookModal";

// import ModalBook from './ModalBook';

export const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [smHide, setSmHide] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [showBookDemo, setShowBookDemo] = useState(false);

  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1020) {
        console.log("Mobile screen detected!");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
    setSmHide(!smHide);
  };
  const closeMenu = () => {
    setToggleMenu(!toggleMenu);
    if (window.innerWidth <= 1020) {
      console.log("Mobile screen detected!");
      setSmHide(!smHide);
    }
  };
  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };
  return (
    <div className="nav">
      <div
        className={
          smHide
            ? "nav px-4 md:px-20 py-4 text-white flex  justify-between items-center"
            : "nav nav-sm "
        }
      >
        {smHide ? (
          <div>
            <NavLink to="/">
              <img className="logo" src={logo} alt="log" />
            </NavLink>
          </div>
        ) : null}
        <div
          className={
            toggleMenu
              ? "md:flex  md:pt-0 pt-10 w-full md:w-auto"
              : "hidden md:flex"
          }
          id="menu"
        >
          <ul>
            <li className="px-3 py-2 border-b cursor-pointer md:inline-block hover:text-gray-500 md:border-none active">
              <NavLink to="/" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li className="relative px-3 py-2 border-b cursor-pointer dropdown md:inline-block hover:text-gray-500 md:border-none">
              <NavLink to="/Services" onClick={closeMenu}>
                Services
              </NavLink>
            </li>
            {/* <li className="px-3 py-2 border-b cursor-pointer md:inline-block hover:text-gray-500 md:border-none">
              <NavLink to="/OurKreators" onClick={closeMenu}>
                Our Kreators
              </NavLink>
            </li> */}
            <li className="px-3 py-2 border-b cursor-pointer md:inline-block hover:text-gray-500 md:border-none">
              <NavLink to="/FAQs" onClick={closeMenu}>
              FAQs
              </NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer md:inline-block hover:text-gray-500 md:border-none">
              <NavLink to="/portfolio" onClick={closeMenu}>
              Portfolio
              </NavLink>
            </li>
            <li className="flex px-3 py-2 sm:inline-block">
              <a className="flex items-center justify-center gap-2 btn btn-yellow" onClick={BookDemoHandler}>
        
                <span className="z-20 flex items-center gap-3 font-semibold text-center">
                Schedule A Call 
                </span>
              </a>
            </li>
          </ul>
          {/* <button onClick={BookDemoHandler} className="btn btn-primary hide-sm"> Book A Demo</button> */}
          
        </div>

        <div className="cursor-pointer md:hidden nav-menu">
          <input
            className="hidden menu-btn"
            type="checkbox"
            id="menu-btn"
            checked={toggleMenu}
            onChange={() => {}}
          />
          <label
            onClick={handleToggle}
            className="relative block px-2 py-4 cursor-pointer select-none menu-icon md:hidden"
            for="menu-btn"
          >
            <span className="relative flex items-center navicon bg-white-darkest"></span>
          </label>
        </div>
      </div>
      {/* <ModalBook isVisible={showBookDemo} onClose={() => setShowBookDemo(false)}/> */}
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
    </div>
  );
};

import React from "react";
import "./logslider.css";
import brandLogoOne from "../icon/brand-logo/1.png";
import brandLogoTwo from "../icon/brand-logo/2.png";
import brandLogoThree from "../icon/brand-logo/3.png";
import brandLogoFour from "../icon/brand-logo/4.png";
import brandLogoFive from "../icon/brand-logo/5.png";
import brandLogoSix from "../icon/brand-logo/6.png";
import brandLogoSeven from "../icon/brand-logo/7.png";
import brandLogoEight from "../icon/brand-logo/8.png";
import brandLogoNine from "../icon/brand-logo/9.png";
import brandLogoTen from "../icon/brand-logo/10.png";
import brandLogoEleven from "../icon/brand-logo/11.png";
import brandLogoTwelve from "../icon/brand-logo/12.png";
import brandLogoThirteen from "../icon/brand-logo/13.png";
import brandLogoFourteen from "../icon/brand-logo/14.png";
import brandLogoFifteen from "../icon/brand-logo/15.png";
import brandLogoSixteen from "../icon/brand-logo/16.png";
import brandLogoSeventeen from "../icon/brand-logo/17.png";
import brandLogoEighteen from "../icon/brand-logo/18.png";
import brandLogoNineteen from "../icon/brand-logo/19.png";
import brandLogotTwenty from "../icon/brand-logo/20.png";
import brandLogotTwentyOne from "../icon/brand-logo/21.png";
import brandLogotTwentyTwo from "../icon/brand-logo/22.png";
import brandLogotTwentyThree from "../icon/brand-logo/23.png";
import brandLogotTwentyFour from "../icon/brand-logo/24.png";
import brandLogotTwentyFive from "../icon/brand-logo/25.png";
import brandLogotTwentySix from "../icon/brand-logo/26.png";
import brandLogotTwentySeven from "../icon/brand-logo/27.png";
import brandLogotTwentyEight from "../icon/brand-logo/28.png";
import brandLogotTwentyNine from "../icon/brand-logo/29.png";
import brandLogotThirty from "../icon/brand-logo/30.png";
import brandLogotThirtyOne from "../icon/brand-logo/31.png";
import brandLogotThirtyTwo from "../icon/brand-logo/32.png";
import brandLogotThirtyThree from "../icon/brand-logo/33.png";
import brandLogotThirtyFour from "../icon/brand-logo/34.png";
import brandLogo from "../icon/brand-logo/13.png";

export const LogoSlider = () => {
  return (
    <div>
      
      <div className="slider">
        <div className="slide-track">
        
          <div className="slide">
            <img src={brandLogoOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFive} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSix} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEight} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNine} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyFive} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentySix} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentySeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyEight} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyNine} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotThirty} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotThirtyOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotThirtyTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotThirtyThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotThirtyFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>





          <div className="slide">
            <img src={brandLogoEleven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFourteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFifteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSixteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeventeen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEighteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNineteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwenty} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
        </div>
      </div>
      <div className="mt-0 sm:mt-8 slider">
        <div className="slide-track2">
          <div className="slide">
            <img src={brandLogoEleven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFourteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFifteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSixteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeventeen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEighteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNineteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwenty} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFive} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSix} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEight} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNine} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>
        

        </div>
      </div>
    </div>
  );
};
